<template>
  <!-- <van-nav-bar
    title="Home"
    nav-bar-background-color="@blue"
    style="background-color: @blue"
  /> -->
  {{ this.getStoreFromAPI($route.query.uuid) }}
  <div class="home" v-if="GPS_switch">
    <van-image width="100%" :src="$route.query.imgurl" lazy-load fit="cover" />

    <h3>
      You're at <font color="#2ae0c8">{{ $route.query.name }}</font>
    </h3>

    <van-row>
      <van-col span="2"></van-col>
      <van-col span="20"
        ><van-button
          round
          icon="qr"
          type="primary"
          size="large"
          @click="getDiscountCode()"
          >Get Discount Code</van-button
        ></van-col
      >
      <van-col span="2"></van-col>
    </van-row>

    <van-row style="margin-top: 5%">
      <van-col span="2"></van-col>
      <van-col span="20"
        ><van-button
          round
          icon="revoke"
          type="default"
          size="large"
          to="../Home/"
          >No, I'm not.</van-button
        ></van-col
      >
      <van-col span="2"></van-col>
    </van-row>

    <van-overlay :show="showCode" @click="showCode = false">
      <div class="wrapper">
        <div class="block">
          <h3>
            <font style="color: white">{{ this.store.dcode }}</font>
          </h3>
        </div>
      </div>
    </van-overlay>
  </div>

  <div v-else>
    <van-empty
      class="custom-image"
      image="https://vkceyugu.cdn.bspapp.com/VKCEYUGU-6b96248f-9e27-425f-9eef-5ddb9ef26220/4d0e36a1-d72e-440e-aba3-146b007d0d5b.png"
      description="Ooops! Where r u ?"
    />
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
const axios = require("axios");
import { Toast } from "vant";
// import { reactive } from "vue";
import { GPS } from "../../methods/gps.js";
import { ref } from "vue";

let latitude = 0.0;
let longitude = 0.0;

// let store_latitude = 0.0;
// let store_longitude = 0.0;
// let store;

export default {
  name: "Home",
  data() {
    return {
      info: {},
      AroundStoreName: "La Postte",
      latitude: 0.0,
      longitude: 0.0,
      GPS_switch: true,
      openOnce: 0,
    };
  },
  components: {
    // HelloWorld,
    // [Button.name]: Button,
    // [Toast.name]: Toast,
    // [SubmitBar.name]: SubmitBar,
  },
  setup() {
    const onSubmit = () => Toast("点击按钮");
    const activeNames = ref(["1"]);
    const showCode = ref(false);
    const show = ref(false);

    let store = {};

    return {
      show,
      showCode,
      onSubmit,
      activeNames,
      store,
    };
  },
  methods: {
    getStoreFromAPI(uuid) {
      axios
        .get("https://api.dr.search.ren/getStoreByUUID?uuid=" + uuid)
        .then((response) => {
          console.log(response);
          this.store = response.data.store;
        });
    },
    getPosition() {
      let that = this;
      return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            function (position) {
              latitude = position.coords.latitude;
              longitude = position.coords.longitude;
              let data = {
                latitude: latitude,
                longitude: longitude,
              };

              that.GPS_switch = true;
              console.log(latitude, longitude);
              resolve(data);
            },
            function () {
              that.GPS_switch = false;
              console.log("Fail to get GPS info.");
              Toast("Please turn on GPS.");
              reject(arguments);
            }
          );
        } else {
          console.log("Fail to get GPS info.");
          Toast("Please set GPS on.");
          reject("你的浏览器不支持当前地理位置信息获取");
        }
      });
    },
    getDistance(latA, lngA, latB, lgnB) {
      return GPS.distance(latA, lngA, latB, lgnB);
    },
    getDiscountCode() {
      this.getPosition()
      console.log(this.store);
      // let that = this;
      if (
        this.getDistance(
          latitude,
          longitude,
          this.store.latitude,
          this.store.longitude
        ) < 150.0
      ) {
        console.log("yes");
        this.showCode = true;

        if (this.openOnce == 0) {
          console.log("openOnce");
          let that = this;
          axios
            .get(
              "https://api.dr.search.ren/addRecord?latitude=" +
                latitude +
                "&longitude=" +
                longitude +
                "&uuid=" + 
                that.store.uuid
            )
            .then((response) => {
              console.log(response);
            });

          this.openOnce++;
        }
      } else {
        console.log("no");
        Toast("You're not in the store.");
      }
    },
  },
  created() {
    this.getPosition();
  },
};
</script>

<style>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 120px;
  height: 60px;
  background-color: #2ae0c8;
  text-align: center;
}
</style>