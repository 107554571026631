<template>
  <div class="home">
    <!-- Select Store -->
    <!-- <van-field name="radio" label="Store">
      <template #input>
        <van-radio-group v-model="checked" direction="horizontal">
          <van-radio name="1">Loffti Cafe</van-radio>
          <van-radio name="2">La Postte</van-radio>
        </van-radio-group>
      </template>
    </van-field> -->

    <!-- Store -->
    <van-field
      v-model="state0.value"
      readonly
      clickable
      label="Store"
      placeholder="选择Store"
      @click="state0.showPicker = true"
    />

    <van-popup v-model:show="state0.showPicker" round position="bottom">
      <van-picker
        :columns="columns0"
        @cancel="state0.showPicker = false"
        @confirm="onConfirm0"
      />
    </van-popup>

    <!-- Year -->
    <van-field
      v-model="state.value"
      readonly
      clickable
      label="年份"
      placeholder="选择年份"
      @click="state.showPicker = true"
    />

    <van-popup v-model:show="state.showPicker" round position="bottom">
      <van-picker
        :columns="columns"
        @cancel="state.showPicker = false"
        @confirm="onConfirm"
      />
    </van-popup>

    <!-- Month -->
    <van-field
      v-model="state2.value"
      readonly
      clickable
      label="月份"
      placeholder="选择月份"
      @click="state2.showPicker = true"
    />

    <van-popup v-model:show="state2.showPicker" round position="bottom">
      <van-picker
        :columns="columns2"
        @cancel="state2.showPicker = false"
        @confirm="onConfirm2"
      />
    </van-popup>

    <van-button
      icon=""
      type="primary"
      @click="getDiscountTimes()"
      round
      style="margin-top: 5%"
    >
      Check
    </van-button>
<!-- 
    <h3>
      <font>Discount Times: {{ DiscountTimes }}</font>
    </h3> -->
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
const axios = require("axios");
// import { Toast } from "vant";
import { ref } from "vue";
import { reactive } from "vue";
import { Toast } from 'vant';

export default {
  name: "Home",
  data() {},
  components: {},
  setup() {
    const checked = ref("1");
    let storeUUIDs = [
      // "db9b7665-d769-40d0-acc6-d7ee0940ac58",
      // "f0fb8b0b-e20a-4454-80af-eed4ab2d2343",
    ];

    let columns0 = [];
    const state0 = reactive({
      value: "",
      showPicker: false,
    });
    const onConfirm0 = (value) => {
      state0.value = value;
      state0.showPicker = false;
    };

    const columns = ["2021", "2022", "2023", "2024", "2025"];
    const state = reactive({
      value: "",
      showPicker: false,
    });

    const columns2 = [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
    ];
    const state2 = reactive({
      value: "",
      showPicker: false,
    });

    const onConfirm = (value) => {
      state.value = value;
      state.showPicker = false;
    };

    const onConfirm2 = (value) => {
      state2.value = value;
      state2.showPicker = false;
    };

    let DiscountTimes = 0;

    function getStores() {
      axios.get("https://api.dr.search.ren/getStores").then((response) => {
        
        console.log(response);
         
        for(let i = 0; i < response.data.stores.length; i++){
          columns0[i] = response.data.stores[i].name
          storeUUIDs[i] = response.data.stores[i].uuid
        }

       
      });
    }

    function getDiscountTimes() {
      // console.log(storeUUIDs[checked.value - 1]);
      // console.log("HHH:"+state0.value);

      // console.log()
      
      // for(let i = 0; i < )

      axios
        .get(
          "https://api.dr.search.ren/getRecordNum?uuid=" +
            storeUUIDs[columns0.indexOf(state0.value)] +
            "&year=" +
            state.value +
            "&month=" +
            state2.value
        )
        .then((response) => {
          console.log(response);
          console.log(response.data.num)
          DiscountTimes = response.data.num
          Toast("折扣次数："+response.data.num)
        });
    }

    return {
      columns0,
      state0,
      onConfirm0,
      storeUUIDs,
      checked,
      state,
      columns,
      onConfirm,
      state2,
      columns2,
      onConfirm2,
      getDiscountTimes,
      DiscountTimes,
      getStores,
    };
  },
  methods: {},
  created() {
    this.getStores()
  },
};
</script>

<style>
</style>