<template>
  <!-- <van-nav-bar
    title="About"
    nav-bar-background-color="@blue"
    style="background-color: @blue"
  /> -->
  <div class="about">
    <van-image
      :src="require('../../../assets/df-logo.png')"
      style="margin-top: 10%"
      width="50%"
    />
    <h1>Discount Radar</h1>
    <h3>version: 0.0.1</h3>
  </div>
</template>
